@font-face {
  font-family: 'BentonSans';
  src: url('../fonts/BentonSans.eot?#iefix') format('embedded-opentype'), url('../fonts/BentonSans.otf')  format('opentype'), url('../fonts/BentonSans.woff') format('woff'), url('../fonts/BentonSans.ttf')  format('truetype'), url('../fonts/BentonSans.svg#BentonSans') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'BentonSans';
  src: url('../fonts/BentonSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/BentonSans-Bold.otf')  format('opentype'), url('../fonts/BentonSans-Bold.woff') format('woff'), url('../fonts/BentonSans-Bold.ttf')  format('truetype'), url('../fonts/BentonSans-Bold.svg#BentonSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'BentonSans';
  src: url('../fonts/BentonSans-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/BentonSans-Medium.otf')  format('opentype'),  url('../fonts/BentonSans-Medium.woff') format('woff'), url('../fonts/BentonSans-Medium.ttf')  format('truetype'), url('../fonts/BentonSans-Medium.svg#BentonSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal; }


@font-face {
    font-family: 'BentonSans';
    src: url('../fonts/BentonSans-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/BentonSans-BoldItalic.otf')  format('opentype'), url('../fonts/BentonSans-BoldItalic.woff') format('woff'), url('../fonts/BentonSans-BoldItalic.ttf')  format('truetype'), url('../fonts/BentonSans-BoldItalic.svg#BentonSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic; }


@font-face {
    font-family: 'BentonSans';
    src: url('../fonts/benton-sans-cond-black.eot?#iefix') format('embedded-opentype'),  url('../fonts/benton-sans-cond-black.otf')  format('opentype'), url('../fonts/benton-sans-cond-black.woff') format('woff'), url('../fonts/benton-sans-cond-black.ttf')  format('truetype'), url('../fonts/benton-sans-cond-black.svg#BentonSans-BoldItalic') format('svg');
    font-weight: 900;
    font-style: italic; }


@font-face {
  font-family: 'Haettenschweiler';
  src: url('../fonts/Haettenschweiler.eot?#iefix') format('embedded-opentype'),  url('../fonts/Haettenschweiler.woff') format('woff'), url('../fonts/Haettenschweiler.ttf')  format('truetype'), url('../fonts/Haettenschweiler.svg#Haettenschweiler') format('svg');
  font-weight: normal;
  font-style: normal; }
